define("discourse/plugins/discourse-custom-wizard/discourse/components/custom-wizard-field-upload", ["exports", "discourse/lib/uppy/uppy-upload", "@ember/component", "@ember/owner", "@ember/service", "discourse-common/utils/decorators", "discourse-i18n", "@ember/object"], function (_exports, _uppyUpload, _component, _owner, _service, _decorators, _discourseI18n, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class CustomWizardFieldUpload extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    setup() {
      this.uppyUpload = new _uppyUpload.default((0, _owner.getOwner)(this), {
        id: this.inputId,
        type: `wizard_${this.field.id}`,
        uploadDone: upload => {
          this.setProperties({
            "field.value": upload,
            isImage: this.imageUploadFormats.includes(upload.extension)
          });
          this.done();
        }
      });
      this.uppyUpload.setup(document.getElementById(this.inputId));
    }
    static #_2 = (() => dt7948.n(this.prototype, "setup", [_object.action]))();
    get imageUploadFormats() {
      return this.siteSettings.wizard_recognised_image_upload_formats.split("|");
    }
    get inputId() {
      return `wizard_field_upload_${this.field?.id}`;
    }
    get wrapperClass() {
      let result = "wizard-field-upload";
      if (this.isImage) {
        result += " is-image";
      }
      if (this.fieldClass) {
        result += ` ${this.fieldClass}`;
      }
      return result;
    }
    uploadLabel() {
      return this.uppyUpload?.uploading ? `${_discourseI18n.default.t("wizard.uploading")} ${this.uppyUpload.uploadProgress}%` : _discourseI18n.default.t("wizard.upload");
    }
    static #_3 = (() => dt7948.n(this.prototype, "uploadLabel", [(0, _decorators.default)("uppyUpload.uploading", "uppyUpload.uploadProgress")]))();
    chooseFiles() {
      this.uppyUpload?.openPicker();
    }
    static #_4 = (() => dt7948.n(this.prototype, "chooseFiles", [_object.action]))();
  }
  _exports.default = CustomWizardFieldUpload;
});