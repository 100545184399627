define("discourse/plugins/discourse-custom-wizard/discourse/templates/components/custom-wizard-field-upload", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class={{this.wrapperClass}}>
    <input
      {{did-insert this.setup}}
      disabled={{this.uppyUpload.uploading}}
      id={{this.inputId}}
      class="hidden-upload-field"
      type="file"
      accept={{this.field.file_types}}
      style="visibility: hidden; position: absolute;"
    />
    <DButton
      @translatedLabel={{this.uploadLabel}}
      @translatedTitle={{this.uploadLabel}}
      @icon="upload"
      @disabled={{this.uppyUpload.uploading}}
      @action={{this.chooseFiles}}
      class="wizard-btn wizard-btn-upload-file"
    />
    {{#if this.field.value}}
      {{#if this.isImage}}
        <img src={{this.field.value.url}} class="wizard-image-preview" />
      {{else}}
        {{this.field.value.original_filename}}
      {{/if}}
    {{/if}}
  </div>
  */
  {
    "id": "nGwMkpgI",
    "block": "[[[10,0],[15,0,[30,0,[\"wrapperClass\"]]],[12],[1,\"\\n  \"],[11,\"input\"],[16,\"disabled\",[30,0,[\"uppyUpload\",\"uploading\"]]],[16,1,[30,0,[\"inputId\"]]],[24,0,\"hidden-upload-field\"],[16,\"accept\",[30,0,[\"field\",\"file_types\"]]],[24,5,\"visibility: hidden; position: absolute;\"],[24,4,\"file\"],[4,[38,0],[[30,0,[\"setup\"]]],null],[12],[13],[1,\"\\n  \"],[8,[39,1],[[24,0,\"wizard-btn wizard-btn-upload-file\"]],[[\"@translatedLabel\",\"@translatedTitle\",\"@icon\",\"@disabled\",\"@action\"],[[30,0,[\"uploadLabel\"]],[30,0,[\"uploadLabel\"]],\"upload\",[30,0,[\"uppyUpload\",\"uploading\"]],[30,0,[\"chooseFiles\"]]]],null],[1,\"\\n\"],[41,[30,0,[\"field\",\"value\"]],[[[41,[30,0,[\"isImage\"]],[[[1,\"      \"],[10,\"img\"],[15,\"src\",[30,0,[\"field\",\"value\",\"url\"]]],[14,0,\"wizard-image-preview\"],[12],[13],[1,\"\\n\"]],[]],[[[1,\"      \"],[1,[30,0,[\"field\",\"value\",\"original_filename\"]]],[1,\"\\n\"]],[]]]],[]],null],[13]],[],false,[\"did-insert\",\"d-button\",\"if\"]]",
    "moduleName": "discourse/plugins/discourse-custom-wizard/discourse/templates/components/custom-wizard-field-upload.hbs",
    "isStrictMode": false
  });
});