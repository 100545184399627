define("discourse/plugins/discourse-custom-wizard/discourse/initializers/custom-wizard-redirect", ["exports", "discourse/lib/url", "discourse/lib/plugin-api", "@ember/string"], function (_exports, _url, _pluginApi, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "custom-wizard-redirect",
    after: "message-bus",
    initialize(container) {
      const messageBus = container.lookup("service:message-bus");
      const siteSettings = container.lookup("service:site-settings");
      if (!siteSettings.custom_wizard_enabled) {
        return;
      }
      messageBus.subscribe("/redirect_to_wizard", function (wizardId) {
        const wizardUrl = window.location.origin + "/w/" + wizardId;
        window.location.href = wizardUrl;
      });
      (0, _pluginApi.withPluginApi)("0.8.36", api => {
        api.onAppEvent("page:changed", data => {
          const currentUser = api.getCurrentUser();
          if (currentUser) {
            const redirectToWizard = currentUser.redirect_to_wizard;
            const excludedPaths = siteSettings.wizard_redirect_exclude_paths.split("|").concat(["loading"]);
            if (redirectToWizard && !data.url.includes("ignore_redirect") && data.currentRouteName !== "customWizardStep" && data.currentRouteName !== "customWizard.index" && !excludedPaths.find(p => {
              return data.currentRouteName.indexOf(p) > -1;
            })) {
              _url.default.routeTo(`/w/${(0, _string.dasherize)(redirectToWizard)}`);
            }
          }
        });
      });
    }
  };
});